$mainBlack: #111c31;
$mainWhite: #ffffff;

$mainRed: #53cfd7;
$mainLightRed: #ffe7e8;

$mainGray: #717680;
$mainLightGray: #f3f6fa;

$mainBlue: #1a1a1a;
$mainLightBlue: #d4e0f0;
