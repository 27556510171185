.wrapper {
  position: relative;
  margin: 50px auto auto;
  padding: 52px;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 636px;

  background-color: white;
  justify-content: start;
  align-items: center;

  .buttonContainer {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}

.container {
  display: flex;
  flex-direction: row;
  gap: 25px;
}

h4 {

}

.button {
  max-width: 300px;


//  margin-right: 15px;
  margin-top: 15px;


}

.title {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
margin: 0;
  color: #333333;
}

.subTitle {
  font-family: 'Roboto' , sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  text-align: center;
  font-feature-settings: 'pnum' on, 'lnum' on;

  /* Main/Gray */
margin-top: 24px;
  margin-bottom: 0;
  color: #717680;
}

.errorContainer {
  background: rgba(255, 153, 153, 0.25);
  max-width: 540px;
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;


  p{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin-left: 30px;
    margin-right: 30px;

    /* identical to box height, or 156% */

    text-align: center;
    letter-spacing: 0.03em;
    font-feature-settings: 'pnum' on, 'lnum' on;

    color: #FF0000;
  }
}

.expirationContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.devider {
  width: 100%;
  height: 0px;
  left: 690px;
  top: 688px;
  max-width: 540px;
  margin-top: 45px;
  border: 1px solid #D6D8DB;
}

.coloredText {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  margin: 12px 0 0;
  color: #717680;
  span {
    color: #53CFD7;
  }
}

.coloredTextHeader{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  /* or 138% */

  text-align: center;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
  width: 100%;

  max-width: 540px;
  color: #53CFD7;
  margin-top: 15px;
}
.footerTextContainer{
  margin-top: 15px;
  width: 100%;
  max-width: 540px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.childrenContainer{
  width: 100%;
  max-width: 540px;
  overflow: hidden;
  margin-top: 25px;
  margin-bottom: 45px;
}
.orText{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #333333;
   margin-top: 35px;

}
.subText{

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  width: 100%;
  max-width: 540px;
  text-align: center;
  font-feature-settings: 'pnum' on, 'lnum' on;

  color: #333333;
}


.mobileButton{

}

@media screen and (max-width: 800px) {

  .button {
    max-width: 700px;


  //  margin-right: 15px;
    margin-top: 15px;


  }
.wrapper{
padding-top:30px;
 padding: 20px;
  width: auto;
  }
  .subTitle {

    width: 300px;
    height: 56px;
    left: 38px;
    top: 306px;

    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    padding-right: 20px;
    padding-left: 20px;
  }
.errorContainer{
  max-width: 430px;
  width: 100%;
  height: 90px;
  padding-left: 20px;
  padding-right: 20px;
 margin-left: 10px;
  margin-right: 10px;


  p{

    margin-left: -5px;
    margin-right: -5px;


  }

}
  .title{
    padding-top: 30px;
  }


  .mobileButton{
    width: 100%;
  }
}
