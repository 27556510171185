.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin: 38px 40px 0 auto;
  gap: 20px;
}

@media (max-width: 450px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    gap: 20px;
  }
}
