@import 'src/styles/colors.scss';
@import 'src/styles/typography.scss';

.button {
  @extend .basicItalicBoldBigger;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 266px;
  height: 48px;
  padding: 16px 20px;
  border: none;
  border-radius: 3px;
  color: $mainWhite;
  background-color: $mainRed;
  cursor: pointer;
  margin-top: 20px;

  .iconAfter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 18px;
  }

  &:disabled {
    background-color: $mainLightRed;
  }

}

@media screen and (max-width: 800px) {
  .button{
    width: 100%;


  }
}
