@import 'src/styles/typography.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;

  .text {
    margin-left: 36px;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.containerButtons {
  display: flex;
  gap: 50px;
}

.containerImg {

  height: 198px;
  width: 180px;
  margin-bottom: 30px;
  margin-top: 75px;
}

.marginTen {
  margin-bottom: 27px;
}

.marginDouble {
  margin-bottom: 60px;
}
.thankYouSubtitle{
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-size: 18px;
  line-height: 28px;
  color: #717680;
  text-align: center;
}
