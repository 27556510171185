@import 'src/styles/colors.scss';
@import 'src/styles/mixins.scss';
@import 'src/styles/typography.scss';

.alertsWrapper {
  padding: 53px 36px 0 36px;
  background-color: $mainWhite;

  @include large {
    padding-left: 100px;
    padding-right: 100px;
  }

  .alertMessage {
    @extend .basicItalicBold;
    display: flex;
    align-items: center;
    gap: 22px;
    padding: 10px 39px;
    margin-bottom: 16px;
    line-height: 18px;
    color: $mainRed;
    background-color: $mainLightRed;
    border-radius: 3px;

    .content {
      flex: 1;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.blockersWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding-top: 140px;
  background-color: rgba(0, 0, 0, 0.8);

  .blockerMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    padding: 50px 100px;
    background-color: $mainWhite;
    border-radius: 3px;

    @include large {
      max-width: 80%;
    }

    .iconWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 80px;
      color: $mainBlack;
      background-color: $mainLightBlue;
      border-radius: 100%;
    }

    .title {
      margin-top: 34px;
      font-family: 'Barlow', sans-serif;
      font-size: 40px;
      font-style: italic;
      font-weight: 800;
      line-height: 54px;
      letter-spacing: 0.6px;
      text-transform: uppercase;
      text-align: center;
    }

    .content {
      margin-top: 16px;
      font-family: 'Barlow', sans-serif;
      font-size: 20px;
      font-style: italic;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0.6px;
      text-transform: uppercase;
      text-align: center;
      color: $mainGray;

      strong {
        font-weight: 800;
        color: $mainBlack;
      }
    }
  }
}
