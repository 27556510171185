
.container {
  margin-right: auto;
  margin-left: auto;
  width: 125px;
  height: 196px;

  display: flex;
  flex-direction: column;


  justify-content: center;
  align-items: center;

  background: #FFFFFF;
  border-radius: 20px;

}

.container img {
  left: 479px;
  top: 734px;
  max-height: 72px;

}
.paragraphSubtitle {




  font-family: 'Montserrat', sans-serif;
  width: 170px;

  left: 525px;
  top: 561px;


  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 30px;

  text-align: center;

}
@media screen and (max-width: 800px) {
  .paragraphSubtitle
  {
    width: 100%;
    margin-left: 20px;
  }
  .container{
    width: 100%;
    margin-bottom: -72px;
  }
}
