@import 'src/styles/colors.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  width: 100%;
  margin-top: 30px;
 // overflow: hidden;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .error {
      font-size: 12px;
      line-height: 20px;
      color: $mainRed;
    }
  }

  &.center {
    .top {
      justify-content: center;

      .error {
        margin-left: 25px;
      }
    }
  }
}
