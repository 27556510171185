@import 'src/styles/colors.scss';
@import 'src/styles/typography.scss';

.label {
  @extend .basicItalicBold;
  color: $mainBlack;

  &.bigger {
    font-size: 16px;
    line-height: 19px;
  }
}
