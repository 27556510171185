@import './colors.scss';

.basicItalicBold,
.basicItalicBoldBigger,
.basicItalicBoldBig,
.basicItalicBoldHuge {
  font-family: 'Barlow', sans-serif;
  font-weight: 800;
  font-style: italic;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.basicItalicBold {
  font-size: 14px;
  line-height: 22px;
}

.basicItalicBoldBigger {
  font-size: 16px;
  line-height: 19px;
}

.basicItalicBoldBig {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.015em;
}

.basicItalicBoldHuge {
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.015em;
}

.basicComment,
.basicCommentSmall {
  color: $mainGray;
}

.basicComment {
  font-size: 16px;
  line-height: 28px;
}

.basicCommentSmall {
  font-size: 12px;
  line-height: 20px;
}
