.wrapper {
  margin: 0px auto auto;


  padding-top: 0;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;


  background-color: white;
  justify-content: start;
  align-items: start;
  gap:15px;
  padding-bottom: 10px;

  .buttonContainer {
    display: flex;
    width: 100%;
  justify-content: center;
 }
}

.container {
  display: flex;
  flex-direction: row;
  gap: 25px;
}
h4 {

}
.button{
  max-width: 171px;
  margin: 15px;
}
.enrollmentFormMargin{

}


@media screen and (max-width: 800px) {
  .enrollmentFormMargin{
    div {
    }

  }

.wrapper{
  padding-bottom: 10px;
  margin-right: -7px;


}

}
