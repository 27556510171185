@import 'src/styles/common';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .input {
    @extend .basicInput;
    margin-right: 18px;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 420px) {
      padding: 16px 12px;
    }
  }
}
