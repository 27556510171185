.wrapper {
  display: flex;
  flex-direction: row;
  padding-bottom: 25px;
  margin-bottom: 50px;
  background-color: #ffffff;
  transition: transform .35s ease-in-out;

  .contentWrapper {
    margin-left: 5px;

    .title {
      margin-top: 38px;
      font-family: 'Roboto', sans-serif;
      font-weight: 800;
      font-size: 24px;
      line-height: 30px;
      color: #333333;
    }

    .content {
      margin-right: 32px;
      font-family: 'Montserrat', sans-serif;
      color: #717680;

      a {
        color: #53CFD7;
      }
    }
  }
}

@media (max-width: 450px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    padding: 16px;
    margin-bottom: 50px;
    background-color: #ffffff;
    transition: transform .35s ease-in-out;
  }

  .contentWrapper {
    h4 {
      text-align: center;
    }

    .title {
      margin-top: 38px;
      font-family: 'Roboto', sans-serif;
      font-weight: 800;
      font-size: 24px;
      line-height: 30px;
      color: #333333;
    }

    .content {
      text-align: justify;
      font-family: 'Montserrat', sans-serif;
      color: #717680;

      a {
        color: #53CFD7;
      }
    }
  }
}
