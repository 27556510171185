
.buttonContainer {
    width: 60px;
    height: 60px;
    min-height: 60px;
    min-width: 60px;
    background-color: #53CFD7;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}

.buttonContainer .clicked {
    transform: rotate(45deg);
}
