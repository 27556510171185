.container {
  margin-right: auto;
  margin-left: auto;
  width: 240px;
  height: 257px;

  display: flex;
  flex-direction: column;


  justify-content: center;
  align-items: center;

  background: #FFFFFF;


}

.container img {
  left: 479px;
  top: 734px;
  max-height: 72px;

}

.paragraphSubtitle {




  font-family: 'Montserrat', sans-serif;
  width: 170px;

  left: 525px;
  top: 561px;


  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 30px;

  text-align: center;

}

@media screen and (max-width: 800px) {
  .paragraphSubtitle {
    width: 100%;
    margin-left: 20px;

  }

  .container {
    width: 100%;
    margin-bottom: -72px;
  }
}

@media (max-width: 450px) {
  .container {
    margin-right: auto;
    margin-left: auto;
    height: auto !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    padding-top: 28px;
  }

  .paragraphSubtitle {
    font-family: 'Montserrat', sans-serif;
    left: 525px;
    top: 561px;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
  }
}
