.wrapper{
  // width: 100%;
}
.hyphenate {
  hyphens: auto;
}
.firstHeader {
  /* Title/H1 */

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 58px;
  line-height: 78px;
  letter-spacing: 0.015em;
  margin-bottom: 80px;
  /* identical to box height, or 108% */

  text-align: center;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;

  color:
          #333333;
 // word-wrap: break-word;

/*  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;*/

}

.textHero {
  font-family: 'Montserrat', sans-serif;
  line-height: 28px;
  color: #717680;
}

.textHero span {
  color: #53CFD7;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 28px;
}
@media screen and (max-width: 800px) {
  .firstHeader{
    font-size: 24px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 45px;
    margin-bottom: 30px;

  }

}