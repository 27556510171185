@import 'src/styles/colors.scss';
@import 'src/styles/common.scss';

.wrapper {
  @extend .basicInput;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  max-width: calc(100% - 50px);

  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    color: $mainRed;
    cursor: pointer;
    transition: all 0.15s;

    &.open {
      transform: rotate(180deg);
    }
  }

}

@media screen and (max-width: 800px) {

  .wrapper{
    max-width: 100%;
  }
}



