@import 'src/styles/colors.scss';
@import 'src/styles/typography.scss';

.button {
  @extend .basicItalicBoldBigger;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px 20px;
  border: none;
  border-radius: 3px;
  color: $mainWhite;
  background-color: $mainRed;
  cursor: pointer;

  .iconAfter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 18px;
  }

  &:disabled {
    background-color: $mainLightRed;
  }
}
