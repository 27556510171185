@import 'src/styles/colors.scss';
@import 'src/styles/typography.scss';

.button {
  @extend .basicItalicBoldBigger;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 266px;
  height: 48px;
  padding: 30px 20px;
  border: none;
  border-radius: 3px;
  color: #53CFD7;
  background-color: #F3F2F2;
  cursor: pointer;


  .iconAfter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 18px;
  }

  &:disabled {
    background-color: $mainLightRed;
  }

}
@media screen and (max-width: 800px) {
  .button{
    width: 100%;


  }
}