@import 'src/styles/colors.scss';

.spinner {
  position: static;
  display: inline-block;
  opacity: 1;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  line-height: 1.5715;
  color: $mainRed;
  text-align: center;
  vertical-align: middle;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);

  .spinnerInner {
    width: 25px;
    height: 25px;
    font-size: 20px;
    color: $mainRed;
    position: relative;
    animation: rotating 0.75s linear infinite;
  }
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
