.wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
  transition: transform .35s ease-in-out;

  &.active {
    background: #FFFFFF;
  }

  .cardWrapper {
    margin-right: 25px;
  }

  .inner {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin-top: 99px;

    .title {
      color: #333;
      font-variant-numeric: lining-nums proportional-nums;
      font-family: 'Montserrat', sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 800;
      line-height: 28px;
    }

    .actionElement {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      h4 {
        margin-left: 40px;
      }
    }

    .content {
      padding-bottom: 25px;
      margin-right: 25px;
      font-family: 'Montserrat', sans-serif;
      color: #717680;

      a {
        color: #53CFD7;
      }

      img {
        max-width: 100%;
      }
    }
  }
}


@media (max-width: 450px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    transition: transform .35s ease-in-out;
    padding: 8px;

    &.active {
      background: none;
    }

    .cardWrapper {
      margin-right: 0;
      padding-top: 10px;
    }

    .inner {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      margin-top: 58px;
      padding: 0 16px;
      background: #fff;

      .title {
        font-size: 20px;
        margin-left: 20px !important;
      }

      .actionElement {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 18px;

        h4 {
          margin-left: 40px;
        }
      }

      .content {
        padding-bottom: 25px;
        margin-right: 25px;
        font-family: 'Montserrat', sans-serif;
        color: #717680;

        a {
          color: #53CFD7;
          display: flex;
          justify-content: center;
          padding-bottom: 10px;
        }

        img {
          max-width: 100%;
        }
      }
    }
  }
}
